<template>
  <document-form />
</template>

<script>
  import DocumentForm from './form'
  export default {
    components: { DocumentForm },
  }
</script>
